import { interpret } from 'xstate'
import { defineStore } from 'pinia'
import { getAppMenuStateMachine } from '../apps/app-menu/machine'
import { ref, Ref } from 'vue'

export const useAppMenuStore = defineStore('appMenu', () => {
  const machine = getAppMenuStateMachine()
  const states: Ref<string[]> = ref([])
  const service = interpret(machine)
    .start()
    .onTransition((state) => {
      states.value = state.toStrings()
    })

  function toggleAccountMenu() {
    states?.value.includes('accountMenuVisible')
      ? service.send('HIDE_MENU')
      : service.send('SHOW_ACCOUNT_MENU')
  }

  return { send: service.send, states, toggleAccountMenu }
})
